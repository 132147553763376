@import '~@devsta/ui-kit/scss/main';

.root {
  display: flex;
  flex-direction: column;

  .page {
    max-width: 100%;
  }
}

.sentry-error-embed {
  p,
  h2 {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 500;
    letter-spacing: 1px;
  }
}
